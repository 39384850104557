import { BoatDetails } from '@dmm/lib-react-ui-components';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Tooltip from 'react-tooltip-lite';
import { scrollTo } from '../../../utils/scrollTo';
import { calculateMonthlyPrice, checkPreQualVariation, makePrivateSummaryTrackingEvent, isFinanceable } from '../../../utils/trident';
import { get, find } from 'lodash';
import { DOWN_PAYMENT_PERCENTAGE } from '../../../constants/BoatLoans';
import { BANNER_ATTRIBUTES, BANNER_TEXTS } from '../../../constants/boats';
import { withABTest } from '../../../server/abTest/withABTest';
import { buyerAccountLoginExperiment } from '../../../utils/fsbo/experiments';
import { getBoatDetails, getLocation, getPrice, getTitle, roundLength } from '../../../utils/listingHelper';
import { getSessionItem, removeSessionItem, setSessionItem } from '../../../utils/storage';
import BDPEmailLeadForm from '../BDPSections/BDPEmailLeadForm';
import './styles.css';

const CDN_URL = process.env.REACT_APP_CDN_URL;

export const ContactSuccessNotification = () => {

  return <div className="inline-contact-success">
    <div className="inline-contact-success-info">
      <div className="inline-contact-title">Your email has been sent</div>
    </div>
    <div className="inline-contact-text">
      We have sent your email directly to the seller.
      <br />
      <b>Next, get pre-qualified and on the water faster.</b>
    </div>
  </div>;
};

const createSessionKey = (prefix) => {
  return `${prefix}${window.location.pathname}`;
};

const FinanceSummary = (props) => {
  const tipContentRef = useRef(null);
  const buttonRef = useRef(null);
  const [tipOpen, setTipOpen] = useState(false);
  const [contactSellerClicked, setContactSellerClicked] = useState(false);
  const [showDetailsContact, setShowDetailsContact] = useState(false);
  const [showDetailsSuccess, setShowDetailsSuccess] = useState(false);
  const [showLeadOverlay, setShowLeadOverlay] = useState(false);
  const {abTestConfiguration, listing, tridentTeaserRate, tridentTermInMonths, tridentLoanAmount, isMobile, breakpoint, openModalPostLead, userRef, userEmail} = props;

  const downPayment = tridentLoanAmount * (DOWN_PAYMENT_PERCENTAGE / 100);
  const monthlyPrice =  calculateMonthlyPrice(tridentTeaserRate, tridentTermInMonths, tridentLoanAmount - downPayment, false);
  const length = roundLength(get(listing, 'specifications.dimensions.lengths.nominal.ft')) + '\'';
  const listingAttributes = get(listing,  'attributes', []);
  const bannerAttribute = find(listingAttributes, (a) => BANNER_ATTRIBUTES.includes(a));
  const bannerText = get(BANNER_TEXTS, bannerAttribute);

  const isUserLoginExperiment = buyerAccountLoginExperiment.isActive(abTestConfiguration, listing);
  const price = get(listing, 'price.type.amount.USD');
  //We want elements of the connect info to display for all private sellers, just not specific CTAs for those not eligible for pre-qual
  const showFinanceCTAs = isFinanceable(price, listing?.year);
  const userEmailAvailable = userEmail !== undefined;

  const toggleTip = () => {
    setTipOpen(!tipOpen);
  };

  const handleContactButtonClick = (event, actionLabel) => {
    event.preventDefault();
    document.querySelector('body').classList.add('scroll-blocked');
    const contactTracking = makePrivateSummaryTrackingEvent(actionLabel, 'click');
    window.dataLayer.push(contactTracking);
    setShowLeadOverlay(true);
    setShowDetailsContact(true);
    setShowDetailsSuccess(false);
    if (isUserLoginExperiment) {
      document.querySelector('#privateSellerBoatSummaryV3-stickyButtons').classList.add('hidden');
    }
  };

  const handleLoginContactButtonClick = (event, actionLabel) => {
    event.preventDefault();
    const contactTracking = makePrivateSummaryTrackingEvent(actionLabel, 'click');
    window.dataLayer.push(contactTracking);

    if (!userEmail) {
      userRef?.current?.onOpenModal();
      // Set a session storage item to handle the case when the user performs a
      // social login and a redirect occurs.
      setSessionItem(createSessionKey('displayContactSeller'), true);
    } else {
      document.querySelector('body').classList.add('scroll-blocked');
      setShowLeadOverlay(true);
    }
    setContactSellerClicked(true);
  };

  const updateStickyBtnsVisibility = () => {
    const infoContainer = document.querySelector('#prequalified-and-contact-seller');
    const stickyButtons = document.querySelector('#privateSellerBoatSummaryV3-stickyButtons');
    if (infoContainer && stickyButtons) {
      if (isInViewport(infoContainer)) {
        stickyButtons?.classList.add('hidden');
      } else {
        stickyButtons?.classList.remove('hidden');
      }
    }
  };

  const handleCloseContactForm = () => {
    document.querySelector('body').classList.remove('scroll-blocked');
    setShowDetailsContact(false);
    setShowLeadOverlay(false);
    removeSessionItem(createSessionKey('displayContactSeller'));
    updateStickyBtnsVisibility();
  };

  const handleDetailsSuccess = (ld) => {
    if (openModalPostLead){
      openModalPostLead(ld);
    }
    const contactSubmitTracking = makePrivateSummaryTrackingEvent('contact form submit', 'contact form submit');
    window.dataLayer.push(contactSubmitTracking);
    setShowDetailsSuccess(true);
    handleCloseContactForm();
  };


  const bodyClick = (e) => {
    if (tipContentRef && tipContentRef.current === e.target) {
      return;
    }
    setTipOpen(false);
  };

  const handleCustomizeLinkClick = (event) => {
    event.preventDefault();
    const customizeTracking = makePrivateSummaryTrackingEvent('customize', 'click');
    window.dataLayer.push(customizeTracking);
    scrollTo('#payment-calculator-anchor', { behavior: 'smooth' });
  };

  const trackPreQualLinkClick = (actionLabel) =>{
    const preQualTracking = makePrivateSummaryTrackingEvent(actionLabel, 'click');
    window.dataLayer.push(preQualTracking);
  };

  useEffect(() => {
    const sessionKey = createSessionKey('displayContactSeller');
    const displayContactSeller = getSessionItem(sessionKey);
    const showLeadForm = displayContactSeller || contactSellerClicked;

    if (userEmailAvailable && userEmail && showLeadForm) {
      setShowLeadOverlay(true);
      setShowDetailsContact(true);
      setContactSellerClicked(false);
      if (isMobile) {
        document.querySelector('#privateSellerBoatSummaryV3-stickyButtons').classList.add('hidden');
      }
    }
  }, [userEmailAvailable, userEmail, contactSellerClicked]);

  useEffect(() => {
    // Disable the email field for the experiment because there is not a an easy
    // way to disable the email field in the BDPEmailLeadForm
    if (isUserLoginExperiment && userEmail) {
      document.getElementById('one-email')?.setAttribute('disabled', true);
    }
  }, [isUserLoginExperiment, userEmail, showDetailsContact]);

  useEffect(() =>{
    document.addEventListener('mousedown', bodyClick);
    return () =>{
      document.removeEventListener('mousedown', bodyClick);
    };
  }, []);

  const isInViewport = element => {
    const rect = element.getBoundingClientRect();

    return (
      rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.bottom >= 0
    );
  };

  useEffect(() => {
    if (isUserLoginExperiment && userEmailAvailable && isMobile) {
      window.addEventListener('scroll', updateStickyBtnsVisibility);
    }

    return () => {
      window.removeEventListener('scroll', updateStickyBtnsVisibility);
    };
  }, [isUserLoginExperiment, userEmailAvailable, isMobile]);

  return (
    <div id="private-seller-boat-summary-V3-main-container">
      <div className="private-seller-boat-summary-V3-container">
        <div className="summary-container">
          { bannerText && <div className="banner-text">{bannerText}</div>}
          <h1 className="boat-heading">{getTitle(listing)} | {length}</h1>
          <h2 className="boat-location">{getLocation(listing)}</h2>
          <div className="boat-payment-total">{getPrice(listing)}</div>
          {showFinanceCTAs &&
            <div className="boat-payment-container">
              <div className="boat-monthly-payment-container">
                <div className="payment-month">
                  Own for {monthlyPrice}/mo
                  <div className="payment-tooltip">
                    <Tooltip
                      content={
                        <div
                          role="tooltip"
                          ref={tipContentRef}
                          className={classNames('private-seller-boat-summary-monthly-price-tooltip-info', { 'hidden-tooltip': !tipOpen })}>
                          Estimated monthly payment based on a {tridentTermInMonths}-month loan at {tridentTeaserRate}%
                          APR with 20% down payment.
                        </div>
                      }
                      useHover={false}
                      isOpen={tipOpen}
                      eventToggle="onClick"
                      className="monthly-payment-tooltip-wrapper"
                      direction="up"
                      distance={10}
                      background="#FFFFFF"
                    >
                      <div
                        role="icon"
                        ref={buttonRef}
                        className="info-icon"
                        onClick={toggleTip}
                      >
                        <img width="14" height="14" alt="info icon" src={`${CDN_URL}/boat-loans/finance-variant/Info.svg`}></img>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div
                className="customize-link"
                data-reporting-click-internal-link-type="click"
                data-reporting-click-internal-link-id="customize"
                onClick={handleCustomizeLinkClick}>Customize</div>
            </div>
          }
        </div>

        {isMobile &&
          <BoatDetails details={getBoatDetails(listing)} />
        }

        <div id="prequalified-and-contact-seller" className="info-container">
          <div className="buttons-container">
            {showLeadOverlay && <div className="lead-form-overlay" onClick={handleCloseContactForm}></div>}
            {showDetailsSuccess && <ContactSuccessNotification/>}
            {isUserLoginExperiment && userEmailAvailable && (
              <div className="contact-container">
                <div className="contact-instructions-title">
                  <img alt="private seller connect logo" src={`${CDN_URL}/boat-services/private-seller-connect-logo.svg`}/>
                </div>
                <span id="for-sale-by-subtitle">For sale by Private Seller</span>
                {!showDetailsSuccess &&
                  <button
                    id="contact-seller-button"
                    data-testid="contact-seller-button"
                    className="contact-button"
                    data-reporting-click-internal-link-type="click"
                    data-reporting-click-internal-link-id="contact seller"
                    onClick={(e) => handleLoginContactButtonClick(e, 'contact seller')}
                  >
                    Contact Seller
                  </button>
                }
                <div className="contact-instructions-description-container">
                  <p>
                    <b>Connect with confidence. </b>
                    <span>Schedule time to view the boat and make an offer.</span>
                    <br/>
                    <br/>
                    <b>Easy sale. </b>
                    <span>Financing, closing services, warranty, and GAP insurance available.</span>
                  </p>
                </div>
              </div>
            )}
            {!isUserLoginExperiment &&
             <>
               {!showDetailsSuccess && !isMobile &&
                <BDPEmailLeadForm
                  contactFormPosition = "finance"
                  breakpoint={breakpoint}
                  listing={listing}
                  onSuccess={handleDetailsSuccess}
                />
               }
             </>
            }
            {showFinanceCTAs &&
              <div className="prequalify-container">
                <div className="prequalify-title">
                  Get pre-qualified in minutes
                </div>
                <div className="prequalify-instructions">
                  <ul className="summary-step-text">
                    <li>We’ll compare over 15 lenders to get you the best rate & terms</li>
                    <li>Customized financing to meet your needs</li>
                    <li>Over 25 years of marine lending experience</li>
                  </ul>
                </div>
                <button
                  onClick={() => {
                    trackPreQualLinkClick('pre-qualify & get started');
                    checkPreQualVariation({utmCampaign: 'BDPprequalhero', utmContent: 'BDPprequalapplyCTA', listing});
                  }}
                  className="prequalify-button"
                >Get Pre-Qualified</button>
              </div>
            }
          </div>
        </div>
        {isMobile && (isUserLoginExperiment ? (
          <div id="privateSellerBoatSummaryV3-stickyButtons" className="sticky-buttons">
            {userEmailAvailable &&
              <>
                {showFinanceCTAs &&
                  <button
                    onClick={() => {
                      trackPreQualLinkClick('pre-qualify sticky footer');
                      checkPreQualVariation({utmCampaign: 'BDPprequalhero', utmContent: 'BDPprequalapplyCTA', listing});
                    }}
                    className="sticky-prequalify-button"
                    data-reporting-click-internal-link-type="click"
                    data-reporting-click-internal-link-id="pre-qualify sticky footer">Get Pre-Qualified</button>
                }
                {!userEmail &&
                  <button
                    className="sticky-contact-button"
                    data-reporting-click-internal-link-type="click"
                    data-reporting-click-internal-link-id="message seller sticky footer"
                    onClick={(e) => handleLoginContactButtonClick(e, 'contact seller sticky footer')}>Contact Seller</button>
                }
                {userEmail && (
                  <button
                    className="sticky-contact-button"
                    data-reporting-click-internal-link-type="click"
                    data-reporting-click-internal-link-id="contact seller sticky footer"
                    onClick={(e) => handleContactButtonClick(e, 'contact seller sticky footer')}>Contact Seller</button>
                )}
              </>
            }
          </div>
        ) : (
          !showDetailsContact && (
            <div className="sticky-buttons">
              {showFinanceCTAs &&
                <button
                  onClick={() => {
                    trackPreQualLinkClick('pre-qualify sticky footer');
                    checkPreQualVariation({utmCampaign: 'BDPprequalhero', utmContent: 'BDPprequalapplyCTA', listing});
                  }}
                  className="sticky-prequalify-button"
                  data-reporting-click-internal-link-type="click"
                  data-reporting-click-internal-link-id="pre-qualify sticky footer">Get Pre-Qualified
                </button>
              }
              <button
                className="sticky-contact-button"
                data-reporting-click-internal-link-type="click"
                data-reporting-click-internal-link-id="contact seller sticky footer"
                onClick={(e) => handleContactButtonClick(e, 'contact seller sticky footer')}>Contact Seller
              </button>
            </div>
          )
        ))}
        {((!isUserLoginExperiment && showDetailsContact && isMobile) ||
          (isUserLoginExperiment && showDetailsContact)) &&
          <div className={classNames('modal-email-form-container', { 'for-desktop': isUserLoginExperiment && !isMobile })}>
            <div className="contact-instructions-title">
              <img alt="private seller connect logo" src={`${CDN_URL}/boat-services/private-seller-connect-logo.svg`}/>
            </div>
            <BDPEmailLeadForm
              contactFormPosition = "finance"
              breakpoint={breakpoint}
              listing={listing}
              initialValues={isUserLoginExperiment && {email: userEmail}}
              onSuccess={handleDetailsSuccess}
            />
            <button
              className="contact-close"
              onClick={() => handleCloseContactForm()}>
              <img width="14" height="14" alt="close icon" src={`${CDN_URL}/img/icons/modal-close-button.svg`}></img>
            </button>
          </div>
        }
      </div>
    </div>
  );
};

export default withABTest(FinanceSummary);
